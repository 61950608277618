import React from "react";
import logo from "../../images/logo_white.svg";

function Header() {
  return (
    <div className="md2:w-[85%] w-[90%] md2:p-0 py-[10px] mx-auto flex flex-wrap gap-[10px] md2:justify-between justify-between h-[80px] items-center">
      <div>
        <img
          src={logo}
          alt="restart logo"
          className=" md2:w-[235px] w-[160px]"
        />
      </div>
      <a href="tel:100559999" className="grid place-content-center rounded-[27.5px] bg-[#e4ff3d] hover:shadow-[#b5cf17] hover:shadow-[0px_0px_10px] transition-all duration-150 md2:w-[220px] md2:h-[50px] w-[170px] h-[30px] md2:text-[20px] text-[16px] headerBtn">
        Заказать звонок
      </a>
    </div>
  );
}

export default Header;
