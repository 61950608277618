import React from "react";
import "./LoaderStyle.css";

function Loader() {
  return (
    <div className="fixed top-0 left-0 w-full h-[100vh] grid place-content-center bg-[rgba(0,0,0,0.5)] z-50">
      <div class="loader"></div>
    </div>
  );
}

export default Loader;
