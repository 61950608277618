import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-flip";
import "swiper/css/effect-coverflow";
import { EffectCoverflow, Navigation } from "swiper/modules";

function SwiperCom({ data, className = "" }) {
  return (
    <div className="w-full h-auto">
      <Swiper
        navigation={true}
        effect={"coverflow"}
        grabCursor={true}
        loop={true}
        modules={[Navigation, EffectCoverflow]}
        className={`mySwiper ${className}`}
      >
        {data.map((imageSrc, i) => {
          return (
            <SwiperSlide>
              <div className="w-full h-auto">
                <img src={imageSrc} alt={`Swiper slider ${i + 1}`} />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}

export default SwiperCom;
