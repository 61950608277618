import React from "react";
import Section1 from "../../sections/Section1";
import Section2 from "../../sections/Section2";
import Section3 from "../../sections/Section3";
import Section4 from "../../sections/Section4";
import Section5 from "../../sections/Section5";
import "../../styles/SectoinsStyle.css";
import { Toaster } from "react-hot-toast";

function Main() {
  return (
    <div className="border border-black w-full bg-[#131c20]">
      <div>
        <Toaster />
      </div>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
    </div>
  );
}

export default Main;
