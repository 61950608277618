import React from "react";
import SwiperCom from "../components/swiper/SwiperCom";
import swiper1_1 from "../images/swiper images/Frame_230.webp";
import swiper1_2 from "../images/swiper images/Frame_231.webp";
import swiper2_1 from "../images/swiper images/Frame_427319023.webp";
import swiper2_2 from "../images/swiper images/Frame_427319024.webp";
import swiper3_1 from "../images/swiper images/01.webp";
import swiper3_2 from "../images/swiper images/02.webp";
import swiper3_3 from "../images/swiper images/03.webp";
import swiper3_4 from "../images/swiper images/04.webp";
// import swiper3_5 from "../images/swiper images/05.webp";
import swiper3_6 from "../images/swiper images/06.webp";
import swiper4_1 from "../images/swiper images/01-1.webp";
import swiper4_2 from "../images/swiper images/02-1.webp";
import swiper4_3 from "../images/swiper images/03-1.webp";
import swiper4_4 from "../images/swiper images/04-1.webp";
import swiper4_5 from "../images/swiper images/05-1.webp";
import swiper4_6 from "../images/swiper images/06-1.webp";
import bgFon1 from "../images/Ellipse_1.webp";

function Section3() {
  return (
    <div id="view_cases" className="text-white  overflow-hidden">
      <div className="zIndex0 relative">
        <div className="zIndex0 absolute top-[-100px] left-[-150px]">
          <img
            src={bgFon1}
            alt="background fon 1"
            className="md2:w-[700px] md2:h-[700px] w-[400px] h-[400px]"
          />
        </div>
        <div className="zIndex0 absolute lg:top-[80vh] right-[-150px]">
          <img
            src={bgFon1}
            alt="background fon 1"
            className="md2:w-[700px] md2:h-[700px] w-[400px] h-[400px]"
          />
        </div>
      </div>
      {/* <div className="zIndex10 pt-[40px] lg:w-[78%] w-[80%] mx-auto grid xl:grid-cols-[45%_55%] lg:grid-cols-[40%_60%] grid-cols-1 gap-[50px]">
        <div className="">
          <div className="lg:mb-0 mb-[20px]">
            <h2 className="zIndex10 md2:p-[20px] py-[20px] uppercase xl:text-[40px] lg:text-[30px] text-[24px] titles">
              мы Делаем в <span className="text-[#e4ff3d]">digital</span>
            </h2>
            <p className="xl:text-[20px] lg:text-[18px] text-[16px] lg:mt-[30px] mt-[10px] text">
              Наша компания специализируется на создании впечатляющих цифровых
              решений, которые удовлетворяют потребности современного рынка
            </p>
          </div>
          <div className="lg:hidden block">
            <h2 className="uppercase xl:text-[20px] text-[16px] titles flex gap-[10px]">
              <span className="text-[#e4ff3d]">Более</span>
              <span>12 лет опыта разработки</span>
            </h2>
            <p className="text md2:text-[16px] text-[14px] lg:mt-[15px] mt-[10px] md2:max-w-[85%]">
              Благодаря более чем 12 годам опыта в разработке, мы обладаем
              глубоким пониманием технологических требований и эффективных
              стратегий решения проблем
            </p>
          </div>
          <div className=" lg:mt-[100px] mt-[60px]">
            <SwiperCom
              data={[
                swiper3_1,
                swiper3_2,
                swiper3_3,
                swiper3_4,
                // swiper3_5,
                swiper3_6,
              ]}
            />
          </div>
        </div>
        <div className="">
          <div>
            <div className="lg:block hidden mt-[30px]">
              <h2 className="uppercase xl:text-[20px] text-[16px] titles flex gap-[10px]">
                <span className="text-[#e4ff3d]">Более</span>
                <span>12 лет опыта разработки</span>
              </h2>
              <p className="text text-[16px] mt-[15px] max-w-[85%]">
                Благодаря более чем 12 годам опыта в разработке, мы обладаем
                глубоким пониманием технологических требований и эффективных
                стратегий решения проблем
              </p>
            </div>
            <div className="lg:mt-[30px] mt-[-15px] md2:block grid gap-[30px]">
              <div className="grid md2:grid-cols-2 grid-cols-1 gap-[30px]">
                <SwiperCom data={[swiper1_1, swiper1_2]} />
                <SwiperCom data={[swiper2_1, swiper2_2]} />
              </div>
              <div className="md2:mt-[40px]">
                <SwiperCom
                  data={[
                    swiper4_1,
                    swiper4_2,
                    swiper4_3,
                    swiper4_4,
                    swiper4_5,
                    swiper4_6,
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="zIndex10 pt-[40px] lg:w-[78%] w-[80%] mx-auto">
        <div className="grid xl:grid-cols-[45%_55%] lg:grid-cols-[40%_60%] grid-cols-1 lg:gap-[50px] gap-[25px] lg:items-center lg:h-[100vh]">
          <div className="lg:mt-[-100px]">
            <h2 className="zIndex10 py-[20px] uppercase xl:text-[40px] lg:text-[30px] text-[24px] titles">
              мы Делаем в <span className="text-[#e4ff3d]">digital</span>
            </h2>
            <p className="xl:text-[20px] lg:text-[18px] text-[16px] lg:mt-[30px] mt-[10px] text">
              Наша компания специализируется на создании впечатляющих цифровых
              решений, которые удовлетворяют потребности современного рынка
            </p>
          </div>
          <div className="lg:mt-[-100px]">
            <SwiperCom
              data={[
                swiper3_1,
                swiper3_2,
                swiper3_3,
                swiper3_4,
                // swiper3_5,
                swiper3_6,
              ]}
            />
          </div>
        </div>
        <div className="lg:grid flex flex-col-reverse xl:grid-cols-[55%_45%] lg:grid-cols-[60%_40%] gap-[50px]  lg:pb-[20vh] lg:mt-0 mt-[50px] lg:items-center">
          <div className="lg:mt-[30px] mt-[-15px]">
            <SwiperCom
              data={[
                swiper4_1,
                swiper4_2,
                swiper4_3,
                swiper4_4,
                swiper4_5,
                swiper4_6,
              ]}
            />
          </div>
          <div className="x mt-[30px]">
            <h2 className="uppercase xl:text-[20px] text-[16px] titles flex gap-[10px]">
              <span className="text-[#e4ff3d]">Более</span>
              <span>12 лет опыта разработки</span>
            </h2>
            <p className="text text-[16px] mt-[15px] max-w-[85%]">
              Благодаря более чем 12 годам опыта в разработке, мы обладаем
              глубоким пониманием технологических требований и эффективных
              стратегий решения проблем
            </p>
            <div className="grid md2:grid-cols-2 grid-cols-1 gap-[30px] mt-[20px]">
              <SwiperCom data={[swiper1_1, swiper1_2]} />
              <SwiperCom data={[swiper2_1, swiper2_2]} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section3;
