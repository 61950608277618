import React from "react";
import Header from "../components/header/Header";
import switchIcon from "../images/Frame_1.webp";
import button from "../images/Frame_7.webp";
import bgFon1 from "../images/Ellipse_1.webp";
import bgFon2 from "../images/Untitled10-1266x880_.webp";
import { Link } from "react-scroll";

function Section1() {
  return (
    <div className="relative h-[100vh] lg:min-h-[700px] min-h-[600px] md2:max-h-[1000px] max-h-[800px]">
      <div className="absolute top-0 left-0 h-[100vh] lg:min-h-[700px] min-h-[600px] md2:max-h-[1000px] max-h-[800px] w-full overflow-hidden">
        <div className="w-full h-full relative">
          <div className="absolute md2:top-[-180px] top-[-80px] md2:left-[-100px] left-[-150px]">
            <img
              src={bgFon1}
              alt="background fon 1"
              className="md2:w-[700px] md2:h-[700px] w-[400px] h-[400px] fonAnimation1"
            />
          </div>
          <div className="absolute  md:right-[-80px] md:top-[-80px] top-[-30px] right-[-100px] z-10">
            <img
              src={bgFon2}
              alt="background fon 2"
              className="md2:h-[800px] md2:w-[800px] md:h-[500px] md:w-[500px] h-[350px] w-[350px] fonAnimation2"
            />
          </div>
          <div className="absolute md2:bottom-[-250px] md2:right-[-250px] bottom-[-50px] right-[-50px] z-0">
            <img
              src={bgFon1}
              alt="background fon 2"
              className=" lg:h-[750px] lg:w-[750px] h-[300px] w-[300px] fonAnimation1"
            />
          </div>
        </div>
      </div>
      <div className="absolute top-0 left-0 h-[100vh] lg:min-h-[700px] min-h-[600px] md2:max-h-[1000px] max-h-[800px] fon w-full z-20 md2:block flex flex-col md2:justify-start justify-between">
        <Header />
        <div
          className="lg:w-[78%] w-[80%] mx-auto flex md2:justify-between justify-end md2:flex-row flex-col-reverse md2:mt-[13vh]"
          style={{ height: "calc(100%-80px)" }}
        >
          <div className="md2:pb-0 pb-[130px]">
            <div className="sec1MainText">
              <h1 className="uppercase text-white">Наша разработка</h1>
              <div className="flex xl:gap-[25px] md2:gap-[15px] gap-[10px] items-center">
                <img
                  src={switchIcon}
                  alt="switch"
                  className="xl:w-[76px] xl:h-[42px] md2:w-[56px] md2:h-[32px] w-[36px] h-[22px]"
                />
                <h1 className="uppercase text-[#e4ff3d]">Включит</h1>
              </div>
              <h1 className="uppercase text-white">ваш бизнес</h1>
            </div>
            <Link
              smooth
              spy
              to="view_cases"
              className="cursor-pointer grid place-content-center  hover:shadow-[0px_0px_10px] hover:shadow-white rounded-[34px] font-semibold xl:w-[255px] xl:h-[68px] w-[180px] h-[44px] bg-white uppercase xl:text-[20px] text-[12px] xl:mt-[70px] mt-[30px] active:bg-gray-400 hover:bg-gray-300 transition-all duration-100"
            >
              СМОТРЕТЬ КЕЙСЫ
            </Link>
          </div>
          <div className="flex md2:justify-end md2:items-end md2:mb-0 md:mb-[70px] mb-[50px]">
            <Link
              smooth
              spy
              to="submit_your_application"
              className="cursor-pointer transition-all duration-100 rounded-[50%] hover:shadow-[0px_0px_10px] hover:shadow-yellow-300"
            >
              <img
                src={button}
                alt="button"
                className="xl:w-[288px] xl:h-[288px] md:w-[150px] md:h-[150px] w-[120px] h-[120px] z-0"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section1;
