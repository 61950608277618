import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import closeIcon from "../images/i.webp";
import Loader from "../components/loader/Loader";
import toast from "react-hot-toast";

function Section5() {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phoneNumberState, setPhoneNumberState] = useState("");
  const onSubmit = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();

      const token = "7155444213:AAEOW5p9I-OISCYDw1DxXm9OaOoSbIF0ZoA";
      const url = `https://api.telegram.org/bot${token}/sendMessage`;
      const chat_id = -4249384545;
      const body = {
        chat_id: chat_id,
        text: `Почта: ${e.target.email.value}\nФИО: ${e.target.fullname.value}\nНомер: ${e.target.phoneNumber.value}`,
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(body),
      });
      if (response.status === 404) {
        toast.error("Ненайдено");
      }
      if (response.status === 504) {
        toast.error("Подключитесь к интернету");
      }
      if (response.ok) {
        setShowModal(true);
        setLoading(false);
      }

      setTimeout(() => {
        setShowModal(false);
        setLoading(false);
      }, 10000);

      e.target.fullname.value = "";
      e.target.email.value = "";
      e.target.phoneNumber.value = "";
      setPhoneNumberState("+992");
    } catch (error) {
      toast.error("Сообшение не отправлено");
      console.error();
      console.log("error", error);
      setLoading(false);
    }
  };
  return (
    <div id="submit_your_application" className=" lg:pt-[150px] pt-[100px]">
      {loading ? <Loader /> : null}
      <div className="lg:w-[78%] w-[80%] mx-auto">
        <div className="w-full bg-[#e4ff3d] lg:rounded-[46px] rounded-[32px] lg:p-[25px] p-[15px] lg:mb-[200px] mb-[100px]">
          <div className="border-[3px] border-black lg:rounded-[40px] rounded-[28px] lg:p-[50px] lg:pt-[30px] lg:pb-[40px] p-[20px]">
            <h1 className="lg:text-[40px] text-[28px] textTitle font-[700]">
              Оставить заявку
            </h1>
            <p className="text-black lg:text-[20px] text-[16px]">
              Оставьте ваши контакты и мы свяжемся с вами
            </p>
            <form
              onSubmit={onSubmit}
              className="w-full grid lg:grid-cols-4 md2:grid-cols-2 md:grid-cols-1 gap-[20px] mt-[20px]"
            >
              <div className="grid">
                <label
                  name="email"
                  className="text-[16px] text text-[rgb(88,113,42)]"
                >
                  Введите почту
                </label>
                <input
                  required
                  placeholder="main@example.com"
                  name="email"
                  type="email"
                  className="bg-inherit md:w-full border-b border-black py-[15px] outline-none"
                />
              </div>
              <div className="grid">
                <label
                  name="fullname"
                  className="text-[16px] text text-[rgb(88,113,42)]"
                >
                  Введите ФИО
                </label>
                <input
                  required
                  placeholder="John Smith"
                  name="fullname"
                  className="bg-inherit md:w-full border-b border-black py-[15px] outline-none"
                />
              </div>
              <div className="grid">
                <label
                  name="phoneNumber"
                  className="text-[16px] text text-[rgb(88,113,42)]"
                >
                  Введите номер
                </label>
                <PhoneInput
                  // country={"tj"}
                  regions={["america", "europe", "asia", "oceania", "africa"]}
                  placeholder=""
                  masks={{
                    tj: "...-..-....",
                    at: "(....) ...-....",
                    fr: "(...) ..-..-..",
                  }}
                  value={phoneNumberState}
                  inputStyle={{
                    background: "rgba(0,0,0,0)",
                    borderRight: "none",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "1px solid black",
                    borderRadius: "0",
                    paddingTop: "26px",
                    paddingBottom: "26px",
                    width: "100%",
                  }}
                  onChange={(e) => setPhoneNumberState(e)}
                  inputProps={{
                    name: "phoneNumber",
                    required: true,
                  }}
                  buttonStyle={{ background: "rgba(0,0,0,0)" }}
                />
              </div>
              <div className="flex items-end justify-end">
                <button
                  type="submit"
                  className="hover:shadow-[0px_0px_10px] hover:shadow-black w-full lg:h-[50px] h-[40px] lg:rounded-[25px] rounded-[20px] text-white bg-black text-[14px] transition-all duration-150 hover:bg-gray-800 active:bg-gray-600"
                >
                  Оставить заявку
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {showModal ? (
        <div
          onClick={() => setShowModal(false)}
          className="fixed top-0 left-0 w-full h-[100vh] grid place-content-center transition-all duration-150"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="modalAnimation w-[250px] h-[250px] rounded-[10px] bg-white p-[15px] grid grid-rows-[20px_1fr_auto]"
          >
            <div className="grid justify-end">
              <button onClick={() => setShowModal(false)}>
                <img
                  src={closeIcon}
                  alt="закрыть"
                  className="w-[20px] h-[20px]"
                />
              </button>
            </div>
            <div className="grid place-content-center w-full ">
              <div className="border-[5px] mx-auto border-green-500 rounded-[50%] w-[150px] h-[150px] grid place-content-center mt-[-40px]">
                <div className="border-r-[5px] border-b-[5px] border-green-500 w-[50px] h-[80px] rotate-[45deg] mt-[-20px]"></div>
              </div>
            </div>
            <div>
              <p className="text text-[14px] text-black font-bold text-center">
                Отправлено успешно!
              </p>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Section5;
