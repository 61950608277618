import React, { useEffect, useRef } from "react";
import scrollerIcon from "../images/scroller_text_icon.jpg";

function Section2() {
  const scrollerTopRef = useRef();
  const scrollerBottomRef = useRef();

  const addAnimation = () => {
    scrollerTopRef.current.setAttribute("data-animation", true);
    scrollerBottomRef.current.setAttribute("data-animation", true);
  };

  useEffect(() => {
    if (!window.matchMedia("(prefers-reduced-motoin: reduce)").matches) {
      addAnimation();
    }
  }, [scrollerTopRef, scrollerBottomRef]);
  return (
    <div className="w-full">
      <div
        ref={scrollerTopRef}
        arrow-left="true"
        className="scroller scroller_right w-full border-y  border-white text-white uppercase max-w-[100vw] overflow-hidden h-[96px]"
      >
        <ul className="scroller__inner flex gap-[20px] text-[25px] h-full items-center titles text-nowrap">
          <li>DEVOPS</li>
          <div>
            <img src={scrollerIcon} alt="icon" />
          </div>
          <li>Web разработка</li>
          <div>
            <img src={scrollerIcon} alt="icon" />
          </div>
          <li>боты</li>
          <div>
            <img src={scrollerIcon} alt="icon" />
          </div>
          <li>crm/crs/cp</li>
          <div>
            <img src={scrollerIcon} alt="icon" />
          </div>
          <li>дизайн</li>
          <div>
            <img src={scrollerIcon} alt="icon" />
          </div>
          <li>мобильная разработка</li>
        </ul>
      </div>
      <div
        ref={scrollerBottomRef}
        arrow-right="true"
        className="scroller scroller_left w-full border-y border-white text-white uppercase max-w-[100vw] overflow-hidden h-[96px]"
      >
        <ul className="scroller__inner flex gap-[20px] text-[25px] h-full items-center titles text-nowrap">
          <li>DEVOPS</li>
          <div>
            <img src={scrollerIcon} alt="icon" />
          </div>
          <li>Web разработка</li>
          <div>
            <img src={scrollerIcon} alt="icon" />
          </div>
          <li>боты</li>
          <div>
            <img src={scrollerIcon} alt="icon" />
          </div>
          <li>crm/crs/cp</li>
          <div>
            <img src={scrollerIcon} alt="icon" />
          </div>
          <li>дизайн</li>
          <div>
            <img src={scrollerIcon} alt="icon" />
          </div>
          <li>мобильная разработка</li>
        </ul>
      </div>
    </div>
  );
}

export default Section2;
