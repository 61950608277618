import './App.css';
import Main from './pages/main/Main';

function App() {
  return (
    <div className="">
      <Main/>
    </div>
  );
}

export default App;
