import React from "react";
import bgFon1 from "../images/Ellipse_1.webp";

function Section4() {
  const data = [
    {
      title: "Разработка колл центра",
      description:
        "Создание и настройка колл-центров 'под ключ', обеспечивая все необходимые решения для эффективного ведения бизнеса",
    },
    {
      title: "Devops",
      description:
        "Высокий уровень DevOps и системного администрирования для надежности и эффективности вашей инфраструктуры",
    },
    {
      title: "Веб разработка",
      description:
        "Индивидуальные и качественные решения для создания привлекательных и эффективных интернет-проектов",
    },
    {
      title: "боты",
      description:
        "Разработка ботов, которые автоматизируют и улучшают обслуживание, оптимизируют бизнес-процессы",
    },
    {
      title: "CRM/cms/cp",
      description:
        "Разработка CRM/CMS/CP, обеспечивая эффективное управление отношениями с клиентами, веб-сайтом и панелью управления",
    },
    {
      title: "ДИзайн",
      description:
        "UI/UX интерфейсы, продуктовый, брендбуки, логобуки, презентации",
    },
    {
      title: "Мобильная разработка",
      description:
        "Инновационные и пользовательские приложения для различных платформ",
    },
    {
      title: "Интеграции",
      description:
        "Настройка интеграций, обеспечивая безупречную работу вашей системы с другими сервисами и платформами",
    },
    {
      title: "АВтоматизация бизнес процессов",
      description:
        "Оптимизация рабочих процессов, повышение производительности и снижение затрат",
    },
  ];
  return (
    <div className="text-white lg:mt-0 mt-[80px]">
      <div className="relative zIndex0">
        <div className="absolute zIndex0 top-[-150px] left-[-150px]">
          <img
            src={bgFon1}
            alt="background fon 1"
            className=" md2:w-[700px] md2:h-[700px] w-[400px] h-[400px]"
          />
        </div>
      </div>
      <div className="zIndex10 z-50 lg:w-[78%] w-[80%] mx-auto lg:mt-[150px] mt-[40px]      bg-[#122326] lg:rounded-[32px] rounded-[16px] border border-[#28393c] lg:p-[30px] p-[20px]">
        <h2 className="md2:p-[20px] uppercase xl:text-[40px] lg:text-[30px] text-[24px] md2:leading-auto leading-[26px] titles flex flex-wrap gap-[15px]">
          <span>Что мы</span>
          <span className="text-[#e4ff3d]">предоставляем</span>
        </h2>
        <div className="z-50 grid xl:grid-cols-3 md2:grid-cols-2 grid-cols-1 gap-[25px] mt-[30px]">
          {data.map((el, i) => {
            return (
              <div
                key={i}
                className="cardAnimation rounded-[16px] lg:p-[30px] p-[20px]"
              >
                <h4 className="titles lg:text-[20px] text-[16px] leading-[22px] text-[#e4ff3d] uppercase">
                  {el.title}
                </h4>
                <p className="text lg:text-[16px] text-[14px] lg:mt-[15px] mt-[10px] lg:leading-[20px] leading-[18px]">
                  {el.description}
                </p>
              </div>
            );
          })}
        </div>
        <div className="grid lg:grid-cols-2 gap-[30px] mt-[25px]    ">
          <div className="cardAnimation rounded-[16px] lg:p-[30px] p-[20px]">
            <h4 className="titles lg:text-[20px] text-[16px] leading-[22px] text-[#e4ff3d] uppercase">
              Обучение персонала
            </h4>
            <p className="text lg:text-[16px] text-[14px] lg:mt-[15px] mt-[10px] lg:leading-[20px] leading-[18px]">
              Обучение персонала, чтобы им приобрести необходимые навыки и
              компетенции для успешного выполнения своих задачользователей,
              инициированные исключительно синтетически
            </p>
          </div>
          <div className="cardAnimation rounded-[16px] lg:p-[30px] p-[20px]">
            <h4 className="titles lg:text-[20px] text-[16px] leading-[22px] text-[#e4ff3d] uppercase">
              Анализ
            </h4>
            <p className="text lg:text-[16px] text-[14px] lg:mt-[15px] mt-[10px] lg:leading-[20px] leading-[18px]">
              Анализ проектов компании, предоставляя ценные инсайты и
              рекомендации для оптимизации стратегий и достижения поставленных
              целей
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section4;
